import 'react-toastify/dist/ReactToastify.css'
import './styles/output.css'
import './App.css'

import classNames from 'classnames'
import React, { Suspense, useEffect } from 'react'
import { useClearCache } from 'react-clear-cache'
import reactDom from 'react-dom'
import TagManager from 'react-gtm-module'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import DisplayLoader from './components/DisplayLoader'
import Loader from './components/Loader'
import decodeJwt from './hooks/use-jwt-decode'
import pushRoute from './hooks/use-push-route'
import Routing from './routes'
import Header from './shared/Header'
import Sidebar from './shared/Sidebar'
import { token } from './util/constants'
import { notEqual, ternary } from './util/javascript'
import { loadStateFn } from './util/localStorage'
import { pathName, urlSearchParams } from './util/window'

const isProduction = JSON.parse(process.env?.REACT_APP_IS_GA_REQUIRED || false)
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
}

if (isProduction) {
  TagManager.initialize(tagManagerArgs)
}

const App = () => {
  const dispatch = useDispatch()
  const toggleHeaderButton = useSelector((state) => state.header.toggleButton)
  const { isLatestVersion, emptyCacheStorage } = useClearCache()
  const showSidebar = useSelector((state) => state.auth.showSidebar)
  const { routes } = pushRoute()
  const { jwt } = decodeJwt(loadStateFn(token))
  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage()
    }
  }, [isLatestVersion])
  useEffect(() => {
    if (loadStateFn(token)) {
      jwt()
      routes(pathName, false)
      if (
        notEqual(urlSearchParams().pathName, '/welcome-survey') &&
        notEqual(urlSearchParams().pathName, '/success') &&
        notEqual(urlSearchParams().pathName, '/success-for-wix') &&
        notEqual(urlSearchParams().pathName, '/success-for-instanceId') &&
        notEqual(urlSearchParams().pathName, '/success-for-shop') &&
        notEqual(urlSearchParams().pathName, '/success-for-code') &&
        notEqual(urlSearchParams().pathName, '/success-for-code-wix')
      ) {
        dispatch({
          type: 'AUTH',
          payload: { showSidebar: true },
        })
      }
    }
  }, [])
  return (
    <>
      <Router>
        {showSidebar && <Header />}
        <div className={ternary(showSidebar, '', 'flex-col')}>
          {showSidebar && (
            <div
              className={classNames(
                'flex-none transform lg:translate-x-0 transition ease-in-out delay-150 fixed top-0 bottom-0 left-0 z-30 w-68',
                { 'translate-x-0': toggleHeaderButton },
                { '-translate-x-full': !toggleHeaderButton },
              )}
            >
              <Sidebar />
            </div>
          )}
          <DisplayLoader loggedIn={showSidebar} />
          <Suspense fallback={<Loader loggedIn={showSidebar} />}>
            <Routing />
          </Suspense>

          {reactDom.createPortal(
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />,
            document.body,
          )}
        </div>
      </Router>
    </>
  )
}

export default React.memo(App)
